import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 3rem;
  width: ${(props) => props.props.width || '11.6rem'};
  font-family: Roboto, Arial, sans-serif;
  color: white;
  margin-left: ${(props) => props.props.marginLeft || '2em'};
  padding: 0 1em;
  background: #ebbd34;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #e6aa05;
  }
`

const Button = (props) => {
  return (
    <ButtonWrapper onClick={props.onClick} props={props}>
      {props.element}
      {props.text}
    </ButtonWrapper>
  )
}

Button.defaultProps = {
  onClick: null,
  element: null,
}

Button.propTypes = {
  onClick: PropTypes.func,
}

export default Button
