import styled from '@emotion/styled'

import mq from 'styles/breakpoints'

export const JoinContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${mq['xl']} {
    margin-top: 1em;
    margin-left: 2em;
  }
`
export const JoinContainerAccount = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${mq['sm']} {
    align-items: center;
    padding: 0 1rem;
  }
`

export const JoinInput = styled.input`
  display: flex;
  height: 3rem;
  margin-left: 1.5em;
  font-family: Roboto, Arial, sans-serif;
  text-align: center;
  padding: 0em 2em;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

  ${mq['xl']} {
    margin-left: 0;
  }

  ${mq['xsm']} {
    width: 90%;
  }
`

export const JoinInputAccount = styled.input`
  display: flex;
  height: 3rem;
  font-family: Roboto, Arial, sans-serif;
  text-align: center;
  padding: 0em 2.6em;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

  ${mq['sm']} {
    width: 70%;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }

  ${mq['xsm']} {
    margin: 0.25rem 0;
  }
`

export const JoinButton = styled.button`
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 1.5em;
  margin-left: 0.75em;
  color: black;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: transparent;

  &:hover,
  :focus {
    background-color: rgba(211, 211, 211, 0.3);
    border-radius: 5px;
  }

  &:disabled {
    color: gray;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  ${mq['sm']} {
    text-align: center;
    margin-left: 0em;
  }

  ${mq['xsm']} {
    text-align: left;
    margin-left: 0em;
  }
`
