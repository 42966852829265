import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { BiVideoPlus } from 'react-icons/bi'

import Button from 'components/common/Button'
import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'

const LoadingContainer = styled.div`
  margin-left: -12px;
  margin-right: 7px;
`

const NewMeetingButton = ({ onClickNewMeeting, isLoading }) => {
  const { t } = useTranslation('newMeetingButton')

  return (
    <Button
      text={t('newMeeting')}
      onClick={() => onClickNewMeeting()}
      element={
        isLoading ? (
          <LoadingContainer>
            <LottieShape animationData={LoadingAnimation} size={48} />
          </LoadingContainer>
        ) : (
          <BiVideoPlus size="1.7em" style={{ marginRight: '1em' }} />
        )
      }
    />
  )
}

NewMeetingButton.defaultProps = {
  isLoading: false,
}

NewMeetingButton.propTypes = {
  onClickNewMeeting: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export default NewMeetingButton
